<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="btn-group w-100" role="group">
                   <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                        <i class="fas fa-plus-circle mr-2" v-show="platform == 'desktop'"></i>
                        <span>Novo</span>
                   </button>
                   <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                        <i class="far fa-list-alt mr-2" v-show="platform == 'desktop'"></i>
                        <span>Produtos</span>
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="cat == 2 && total_products > 0">
                            {{ total_products }}
                        </span>
                   </button>
                   <button type="button" class="btn btn-secondary" @click="cat = 3" :class="{'active': cat == 3}" :disabled="cat == 3">
                        <i class="fas fa-images mr-2" v-show="platform == 'desktop'"></i>
                        <span>Vitrine</span>
                   </button>
               </div><!-- /btn-group -->
               <div v-show="loading == true" class="alert alert-warning mt-3" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error" class="alert alert-danger mt-3" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                    <hr />
                    <div class="row ml-0 mt-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="total">
                                <option value="0">Resgatado</option>
                                <option value="1" selected>Disponível</option>
                            </select>
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="listProducts"><i class="fas fa-search mr-2"></i>Buscar</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                </div><!-- /alert-danger -->
                <div class="mt-3 mb-3">
                    <template v-if="cat == 1">
                        <form @submit.prevent="newProduct">
                            <div class="form-group">
                                <label for="name" class="text-light">Nome</label>
                                <input type="text" class="form-control" v-model="product.name" v-text-only id="name" placeholder="Informe o nome do produto" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="price" class="text-light">Preço</label>
                                <input type="text" class="form-control" v-model="product.price" v-number-only id="price" placeholder="Informe o preço do produto" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="total" class="text-light">Quantidade</label>
                                <input type="text" class="form-control" v-model="product.total" v-number-only id="total" placeholder="Informe a quantidade de produtos" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <div class="card text-center mb-3">
                                    <div class="card-header">Imagem do produto</div>
                                        <div class="card-body">
                                        <h5 class="card-title">Envie uma imagem do produto</h5>
                                        <p class="card-text">Essas são as dimensões da imagem: <b class="text-danger">300x300</b> px</p>
                                        <template v-if="product.image">
                                            <div class="card">
                                                <div class="text-center mt-3">
                                                    <img :src="product.image" width="300" height="300" alt="">
                                                </div>
                                                <div class="card-body border-top">
                                                    <a class="btn btn-danger btn-block text-white" @click="deleteImage(false)" role="button">
                                                        <i class="fas fa-trash-alt mr-2"></i>Excluir
                                                    </a>
                                                </div>
                                            </div><!-- /card -->
                                        </template>
                                    </div>
                                    <div class="card-footer">
                                        <div class="progress mb-3" style="height: 20px;" v-show="upload_progress == true">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" :style="{'width': progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                                        </div>
                                        <div class="form-group mb-0" role="file">
                                            <div class="row">
                                                <div class="col">
                                                    <label class="btn btn-primary btn-block mb-0" for="image-upload" :disabled="upload_progress == true">
                                                        <input type="file" accept=".gif, .jpg, .jpeg, .png" id="image-upload" @change="upload">
                                                        <i class="fas fa-upload mr-2"></i>
                                                        <span>Selecionar imagem</span>
                                                    </label>
                                                </div>
                                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                                <div class="col">
                                                    <button type="submit" class="btn btn-success btn-block btn-primary" :disabled="loading == true">
                                                        <i class="fas fa-check-circle mr-2"></i>
                                                        <span>Cadastrar produto</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </template>
                    <template v-else-if="cat == 2 && total_products">
                        <div class="row ml-0 mt-3 mr-0" :class="{'mb-3': platform == 'desktop'}">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="total">
                                    <option value="0">Resgatado</option>
                                    <option value="1" selected>Disponível</option>
                                </select>
                            </div>
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <button class="btn btn-warning btn-block" @click="listProducts">
                                    <i class="fas fa-search mr-2"></i>Buscar
                                </button>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="card mb-3" v-for="(item, index) in products" :key="index">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col text-truncate">{{ item.nome }}</div>
                                    <div class="col-auto">
                                        <button class="btn btn-primary btn-sm btn-block" @click="editProduct(item, index)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-danger btn-sm btn-block" @click="deleteProduct(item, index)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div><!-- /card-header -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">Preço</div>
                                    <div class="col-sm-8 text-muted">{{ item.preco }}</div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-4">Disponíveis</div>
                                    <div class="col-sm-8 text-muted">{{ item.total }}</div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-4">Resgatados</div>
                                    <div class="col-sm-8 text-muted">{{ item.total_vendido }}</div>
                                </div>
                            </div><!-- /card-body -->
                        </div><!-- /card -->
                    </template>
                    <template v-else-if="cat == 3 && !loading">
                        <div class="card text-center mb-3">
                            <div class="card-header">Vitrine</div>
                                <div class="card-body">
                                <h5 class="card-title">Envie suas imagens</h5>
                                <p class="card-text">Essas são as dimensões das imagens do slider: <b class="text-danger">1920x738</b> px</p>
                                <div class="card-columns">
                                    <div class="card" v-for="(item, index) in storeImages" :key="index">
                                        <img :src="item.image" class="card-img-top" alt="">
                                        <div class="card-body border-top">
                                            <a class="btn btn-danger btn-block text-white" @click="deleteImage(index)" role="button">
                                                <i class="fas fa-trash-alt mr-2"></i>Excluir
                                            </a>
                                        </div>
                                    </div><!-- /card -->
                                </div><!-- /card-columns -->
                            </div>
                            <div class="card-footer">
                                <div class="progress mb-3" style="height: 20px;" v-show="upload_progress == true">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" :style="{'width': progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                                </div>
                                <div class="form-group mb-0" role="file">
                                    <div class="row">
                                        <div class="col">
                                            <label class="btn btn-primary btn-block mb-0" for="image-upload" :disabled="upload_progress == true">
                                                <input type="file" accept=".gif, .jpg, .jpeg, .png" id="image-upload" @change="upload">
                                                <i class="fas fa-upload mr-2"></i>
                                                <span>Selecionar imagem</span>
                                            </label>
                                        </div>
                                        <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                        <div class="col">
                                            <a class="btn btn-success btn-block btn-primary" role="button" @click="saveStoreImages" :disabled="isLoading == true">
                                                <i class="fas fa-check-circle mr-2"></i>
                                                <span>Salvar alterações</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 1,
            total: 1,
            error: '',
            product: {
                name: '',
                total: 1,
                price: '',
                image: ''
            },
            progress: 0,
            products: [],
            storeImages: [],
            upload_step: 0,
            total_products: 0,
            loading: false,
            isLoading: false,
            upload_progress: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        newProduct() {

            const self = this;

            if (self.product.name.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o nome do produto!',
                    'warning'
                );
            } else if (self.product.price.toString().trim() == '' || self.product.price.toString().trim() == 0) {
                Swal.fire(
                    'Atenção!',
                    'Informe o preço do produto!',
                    'warning'
                );
            } else if (self.product.total.toString().trim() == '' || self.product.total.toString().trim() == 0) {
                Swal.fire(
                    'Atenção!',
                    'Informe a quantidade do produto!',
                    'warning'
                );
            } else if (self.product.image.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'É obrigatório enviar uma imagem do produto!',
                    'warning'
                );
            }  else {

                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo cadastrar o produto?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.total = 1;
                        self.isLoading = true;

                        api.post('dashboard/store', {
                            product: self.product
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    self.clearProduct();
                                    Swal.fire(
                                        'Parabéns!',
                                        'O produto foi cadastrado com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        listProducts() {

            const self = this;

            self.error = '';
            self.products = [];
            self.loading = true;

            api.get(`dashboard/store?action=products&total=${self.total}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.products = response.data.products;
                    break;
                    case 'nothing_found':
                        self.error = 'Não encontramos nenhum produto!';
                    break;
                    default:
                        self.error = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        listStoreImage() {

            const self = this;

            self.error = '';
            self.upload_step = 1;
            self.storeImages = [];
            self.loading = true;

            api.get(`dashboard/store?action=images`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.storeImages = response.data.store_images;
                    break;
                    default:
                        Swal.fire(
                            'Falha!',
                            response.data.message,
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        saveStoreImages() {

            const self = this;

            if (self.storeImages.length == 0) {
                Swal.fire(
                    'Atenção!',
                    'É obrigatório enviar pelo menos uma imagem!',
                    'warning'
                );
            } else {

                self.isLoading = true;

                api.put('dashboard/store', {
                    action: 'updateImage',
                    storeImages: self.storeImages
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'warning'
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        deleteProduct(item, index) {

            const self = this;

            Swal.fire({
                title: 'Excluir produto',
                html: `Você deseja mesmo excluir o produto "<b>${item.nome}</b>" ?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then(function(result) {

                if (result.value) {

                    self.isLoading = true;

                    api.delete(`dashboard/store?id=${item._id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.removeProductList(index);
                                Swal.fire(
                                    'Parabéns!',
                                    'Produto excluído com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha!',
                                    response.data.message,
                                    'warning'
                                );
                            break;
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        async editProduct(item, index) {
            try {
                const self = this;

                const { value: formValues } = await Swal.fire({
                    title: 'Editar produto',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <label class="d-block text-left mb-0">Nome</label>
                        <input id="swal-input1" class="swal2-input" value="${item.nome}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Preço</label>
                        <input id="swal-input2" class="swal2-input" value="${item.preco}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Disponíveis</label>
                        <input id="swal-input3" class="swal2-input" value="${item.total}" autocomplete="off" spellcheck="false">
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            id: item._id,
                            nome: document.getElementById('swal-input1').value,
                            preco: document.getElementById('swal-input2').value,
                            total: document.getElementById('swal-input3').value
                        }
                    },
                    onOpen: () => {
                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input3").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                    }
                });

                if (formValues instanceof Object) {

                    if (formValues.nome.toString().trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o nome do produto!',
                            'warning'
                        );
                    } else if (formValues.preco.toString().trim() == '' || formValues.preco.toString().trim() == 0) {
                        Swal.fire(
                            'Atenção!',
                            'Informe o preço do produto!',
                            'warning'
                        );
                    } else if (formValues.total.toString().trim() == '' || formValues.total.toString().trim() == 0) {
                        Swal.fire(
                            'Atenção!',
                            'Informe a quantidade do produto!',
                            'warning'
                        );
                    } else {
                        
                        self.isLoading = true;

                        api.put('dashboard/store', {
                            action: 'update',
                            product: formValues
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    self.updateProductList(index, formValues);
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                }
            } 
            catch(e) 
            {
                // window.console.log(e.message)
            }
        },
        updateProductList(index, data) {
            if (this.products[index] != undefined) {
                this.products[index].nome = data.nome;
                this.products[index].preco = data.preco;
                this.products[index].total = data.total;
            }
        },
        removeProductList(index) {
            if (this.products[index] != undefined) {
                this.products.splice(index, 1);
            }
        },
        upload() {

            const self = this;
            const img = new Image();
            const imageDimensionsAllowed = ['300x300','1920x738'];
            const imagem = document.getElementById('image-upload').files[0];
            const imageTypes = ['image/jpg','image/jpeg','image/gif','image/png'];

            img.onload = () => {

                const imageDimensions = `${img.naturalWidth}x${img.naturalHeight}`;

                if (typeof(imagem) == 'object') {

                    if (!imageTypes.includes(imagem.type)) {
                        Swal.fire(
                            'Atenção!',
                            'O formato da imagem é inválido!',
                            'error'
                        );
                    } else if (!imageDimensionsAllowed.includes(imageDimensions)) {
                        Swal.fire(
                            'Atenção!',
                            'Envie a imagem com as dimensões exatas que o sistema pede!',
                            'error'
                        );
                    }
                    else
                    {
                        self.progress = 0;
                        self.upload_progress = true;

                        let formData = new FormData();
                            formData.append('imagem', imagem);

                            api.post('dashboard/upload', formData , {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            transformRequest: [(data) => {
                                return data
                            }],
                            onUploadProgress: (progressEvent) => {
                                let totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                                if (totalLength !== null) {
                                    self.progress = Math.round((progressEvent.loaded * 100) / totalLength);
                                }
                            }
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    if (self.upload_step == 0) {
                                        self.product.image = response.data.file;
                                    } else {
                                        self.storeImages.push({
                                            image: response.data.file
                                        });
                                    }
                                break;
                                default:
                                    Swal.fire(
                                        'Atenção',
                                        response.data.message,
                                        'error'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                window.console.log(e)
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            } 
                        }).finally(() => {
                            self.upload_progress = false;
                        });
                    }
                }
            };

            img.src = URL.createObjectURL(imagem);
        },
        deleteImage(index) {
            if (index === false) {
                this.product.image = '';
            } else {
                this.storeImages.splice(index, 1);
            }
        },
        clearProduct() {
            this.product.name = '';
            this.product.total = 1;
            this.product.price = '';
            this.product.image = '';
        }
    },
    watch: {
        cat(value) {
            this.error = '';

            if (value == 2) {
                this.listProducts();
            } else if (value == 3) {
                this.listStoreImage();
            } else {
                this.upload_step = 0;
            }
        },
        products(values) {
            this.total_products = values.length;
        }
    },
    directives: {
        textOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        },
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^0-9]/g,"");
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.btn .badge {
    top: 3px!important;
}
input#image-upload {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    visibility: hidden;
    text-align: right;
    opacity: 0;
    outline: none;
    background: none;
    cursor: inherit;
    display: block;
}
</style>